import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Paper from '@material-ui/core/Paper';
import { BarChart, StackedBarChart } from 'reaviz';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  ZoomAndPan,
} from '@devexpress/dx-react-chart-material-ui';
import { scaleTime, scaleLinear } from 'd3-scale';
import { ArgumentScale, BarSeries, ValueScale } from '@devexpress/dx-react-chart';

const chartRootStyle = { marginRight: '20px'};
const inputsContainerStyle = { justifyContent: 'center' };

const ChartRoot = props => (
  <Chart.Root {...props} style={chartRootStyle} />
);

const Analytics = ({ location, userDetails }) => {

    if(!location.state){
	    location.state = {timeSeries:[]};
    }

    const data = location.state?.timeSeries; 

    //console.log(scaleTime);

    const valueScale=[0.5,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];


    return (
        <Layout title="Analytics">
        <Seo title="Analytics" />
        {/* <Chart data={data} rootComponent={ChartRoot}>
          <ArgumentScale factory={scaleTime} />
          <ArgumentAxis />
          
          <ValueAxis />
    

          <BarSeries valueField="hrs" argumentField="date" barWidth="500" />
          <ZoomAndPan
            interactionWithArguments={"both"}
            interactionWithValues={"zoom"}
          />
        </Chart> */}
        <div style={{paddingLeft:"30px"}} className="chart">
	{data.length>0 ? <StackedBarChart
          height={300}
          width={300}
          data={data}

          style={{background:"#000"}}
        /> : <div> No Past Bookings found </div> }
        </div>
        </Layout>

    )
}

export default connect(state => ({ userDetails: state.loginReducer.authenticate.user }), null)(Analytics);
